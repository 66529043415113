<template>
  <div>
    <div class="mb-5">
      <h1 class="mt-0 fs-b fs-2500">Détail des habilitations</h1>
    </div>
    <div class="text-left">
      <a
        @click="goToHabilitation()"
        class="fr-link p-0 color-pr fs-875 d-inline-flex"
        title="Retour à la gestion des
        habilitations"
        tabindex="0"
        ><i class="ri-arrow-left-line mr-2"></i>Retour à la gestion des
        habilitations</a
      >
    </div>
    <div class="row fr-container">
      <div class="col-sm-4">
        <div class="detail-habilitation-bloc">
          <div class="detail-habilitation-composant">
            <label>Administration</label><br />
            {{ habilitationItem.coproLibelle }}
          </div>
          <br />
          <div class="detail-habilitation-composant">
            <label>Habilitation</label><br />
            {{ habilitationItem.libelle }}
          </div>
          <br />
          <div class="detail-habilitation-composant">
            <label>Périmètre métier</label><br />
            {{ $filters.perimetreConvertion(habilitationItem.perimetresLabel) }}
          </div>
        </div>
      </div>
      <br v-if="windowWidth < 760" />
      <div class="col-sm-8 fr-table fr-table--bordered">
        <table>
          <caption>
            Liste des entreprises
          </caption>
          <thead v-if="windowWidth > 760">
            <tr>
              <th scope="col">
                <span>Siret ou équivalent</span>
              </th>
              <th scope="col">
                <span>Raison sociale de l'établissement</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="table-line mb-5 select-entreprise"
              v-for="item in siretList"
              :key="item"
            >
              <td>{{ siren.concat(item) }}</td>
              <td>
                {{ getRaisonSociale(item) }}
              </td>
            </tr>
          </tbody>
          <br />
          <caption></caption>
        </table>
        <div class="chargerPlus" v-if="afficherPlus">
          <button
            type="button"
            class="fr-btn charger"
            @click="charger_plus"
            title="Cliquer ici pour charger plus d'établissements"
          >
            Charger plus d'établissements
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { getEtablissementsList } from '../services/NoBusinessService';
import SkipLinkMixin from '../mixins/skip-link-mixin';
import { SOURCE_PAGE_HABILITATION_DETAIL } from '../constantes';

export default {
  mixins: [SkipLinkMixin],
  data() {
    return {
      windowWidth: 0,
      siretList: [],
      siretMap: new Map(),
      page: 0,
      size: 10,
      siren: undefined,
      roleData: undefined
    };
  },

  computed: {
    afficherPlus() {
      if (
        this.habilitationItem.liste_maille.length <= this.size ||
        this.siretList.length === this.habilitationItem.liste_maille.length
      ) {
        return false;
      }
      return true;
    },
    habilitationItem: (_this = this) => _this.$route.params.habilitationItem
  },
  mounted() {
    this.roleData = this.$route.params.roleData;
    if (!this.habilitationItem) {
      this.$router.push({ name: 'Gestion des habilitations' });
    } else {
      if (this.roleData) {
        this.siren = this.roleData.siren;
      } else {
        this.siren = this.$store.state.compte.siren;
      }
      this.loadEtablissement();
      this.charger_plus();
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },

  methods: {
    ...mapActions(['setRouteData']),
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    charger_plus() {
      if (this.siretList.length > 0) {
        this.page++;
      }
      let start = this.page * this.size;
      let end = this.page * this.size + this.size;
      this.siretList = this.siretList.concat(
        this.habilitationItem.liste_maille.slice(start, end)
      );
      if (this.afficherPlus) {
        document.getElementsByClassName('fr-btn charger')[0].focus();
      }
    },
    async loadEtablissement() {
      const etablissements = await getEtablissementsList(this.siren);
      this.siretMap = new Map(
        etablissements.map((key) => [key.siret, key.raisonSocialeEtablissement])
      );
    },
    getRaisonSociale(siret) {
      if (this.siretMap) {
        return this.siretMap.get(this.siren.concat(siret));
      }
    },
    goToHabilitation() {
      const routeData = {
        source: SOURCE_PAGE_HABILITATION_DETAIL,
        roleData: this.roleData
      };
      this.setRouteDataAndPush(routeData, 'Gestion des habilitations');
    },
    setRouteDataAndPush(routeData, routeName) {
      this.setRouteData(routeData)
        .then(() => {
          this.$router.push({
            name: routeName
          });
        })
        .catch((error) => console.error(error));
    }
  },
  beforeUnmount() {
    // Si on  est arrivé depuis la page "gestion des roles" (si roleData est valorisé)
    // Il faut supprimer le siren car il n'a pas été choisi par l'utilisateur sur le selecteur d'entreprise (sauf si on retourne sur la page habilitations)
    let navToHabilitations =
      this.$router.currentRoute.name === 'Gestion des habilitations';
    if (this.roleData && !navToHabilitations) {
      this.$store.dispatch('setSiren', '');
    }
  }
};
</script>
<style lang="scss" scoped>
span {
  font-family: 'Marianne';
  font-size: 0.975rem;
  text-align: left;
  padding: 10px 0;
  z-index: 1;
}

.table-hover > tbody > tr:hover {
  background: $base-color-hover;
  cursor: pointer;
  filter: drop-shadow(1px 1px 2px $base-color);
  font-weight: bold;
  transition: 0.3s;
}
.row {
  width: 78%;
  margin: auto;
}

table {
  margin-top: 15px !important;
  width: 65%;
  margin: auto;
  caption {
    margin-top: 15px !important;
  }
}
.table > div:nth-child(5) {
  width: 162%;
}
h2,
h1 {
  color: $base-color;
}
.detail-habilitation-bloc {
  background-color: #f2f2f9;
  margin-top: 5%;
}

label {
  font-size: 13px;
  color: #666666;
  font-weight: 400;
  vertical-align: middle;
  white-space: nowrap;
}

.detail-habilitation-composant {
  padding: 2% 0px 5% 15%;
}

.chargerPlus {
  display: flex;
  justify-content: center;
  position: relative;
}

a {
  box-shadow: none;
  cursor: pointer;
}

@media only screen and (max-width: 760px) {
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  thead tr {
    color: black;
    font-size: x-small;
    border: 0;
    display: grid;
  }

  tr {
    padding-top: 10px;
    padding-bottom: 10px;
    display: grid;
    width: 95%;
    margin-bottom: 2rem;
  }

  td {
    position: relative;
    padding-left: 1rem !important;
    display: inline-block;
  }

  td:before {
    position: relative;
    top: 0;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  }

  td span {
    position: relative;
    width: 100%;
    white-space: nowrap;
    text-align: start;
  }

  td:nth-of-type(1):before {
    content: 'Siret ou équivalent';
    color: gray;
    font-size: small;
    display: block;
  }
  td:nth-of-type(2):before {
    content: 'Raison sociale';
    color: gray;
    font-size: small;
    display: block;
  }

  .row {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  tr {
    border: 1px solid var(--grey-925-125);
    border-left-color: $base-color !important;
    border-left-width: thick;
    display: grid;
  }

  .fr-table--bordered tbody td,
  .fr-table--bordered tbody th {
    border-bottom: 0;
  }

  .detail-habilitation-bloc {
    width: 95%;
  }

  .select-entreprise {
    margin-bottom: 1rem !important;
  }
}
</style>
